import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`7th July 2020`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">{`You can now use `}<a parentName="li" {...{
          "href": "type://ClientRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html"
        }}>{`type://ClientRequestContext`}</a>{` to build a complex `}<a parentName="li" {...{
          "href": "type://RetryRule:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRule.html"
        }}>{`type://RetryRule`}</a>{` and
`}<a parentName="li" {...{
          "href": "type://CircuitBreakerRule:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerRule.html"
        }}>{`type://CircuitBreakerRule`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2851"
        }}>{`#2851`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`RetryRuleWithContent.<RpcResponse>builder()
                    .onServerErrorStatus()
                     // Now it's the BiPredicate that takes ctx as well.
                    .onException((ctx, ex) -> {
                        RpcRequest request = ctx.rpcRequest();
                        if (request != null &&
                            !safeMethods.contains(request.method())) {
                            return isRetryableException(throwable);
                        } else {
                            return false;
                        }
                    })
                    .thenBackoff(backoff);
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now run the gRPC server/client stubs generated by ScalaPB with Armeria. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2719"
        }}>{`#2719`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Please refer to the fully working `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/tree/main/examples/grpc-scala"
            }}>{`example`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`You can now parse a response trailers from a response body for gRPC-Web using
`}<a parentName="li" {...{
          "href": "type://GrpcWebUtil#parseTrailers(HttpData)"
        }}>{`type://GrpcWebUtil#parseTrailers(HttpData)`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2848"
        }}>{`#2848`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Clients.builder(...)
       .decorator(RetryingClient.newDecorator(
               RetryRuleWithContent.onResponse(response -> {
                   return response.aggregate().thenApply(aggregated -> {
                      HttpHeaders trailers =
                              GrpcWebUtil.parseTrailers(aggregated.content());
                      // Retry if the 'grpc-status' is not equal to 0.
                      return trailers != null &&
                             trailers.getInt(GrpcHeaderNames.GRPC_STATUS) != 0;
                  });
              })))
      .build(MyGrpcStub.class);
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now access the metadata of `}<a parentName="li" {...{
          "href": "type://HttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html"
        }}>{`type://HttpFile`}</a>{` and `}<a parentName="li" {...{
          "href": "type://FileService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileService.html"
        }}>{`type://FileService`}</a>{` asynchronously. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1668"
        }}>{`#1668`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2845"
        }}>{`#2845`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpFile httpFile = HttpFile.of(...);
CompletableFuture<HttpFileAttributes> attrs =
        httpFile.readAttributes(CommonPools.blockingTaskExecutor());
CompletableFuture<ResponseHeaders> headers =
        httpFile.readHeaders(CommonPools.blockingTaskExecutor());
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now easily customize `}<a parentName="li" {...{
          "href": "type://MeterIdPrefixFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/metric/MeterIdPrefixFunction.html"
        }}>{`type://MeterIdPrefixFunction`}</a>{` using
`}<a parentName="li" {...{
          "href": "type://MeterIdPrefixFunction#andThen(MeterIdPrefixFunctionCustomizer):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/metric/MeterIdPrefixFunction.html#andThen(com.linecorp.armeria.common.metric.MeterIdPrefixFunctionCustomizer)"
        }}>{`type://MeterIdPrefixFunction#andThen(MeterIdPrefixFunctionCustomizer)`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2839"
        }}>{`#2839`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`MeterIdPrefixFunction
        .ofDefault("hoge")
        .andThen((registry, log, id) -> id.withTags(
                "grpc-status", log.responseTrailers().get("grpc-status")));
`}</code></pre>
      </li>
      <li parentName="ul">{`Both synchronous and asynchronous APIs to select an `}<a parentName="li" {...{
          "href": "type://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
        }}>{`type://Endpoint`}</a>{` are added to
`}<a parentName="li" {...{
          "href": "type://EndpointSelector:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointSelector.html"
        }}>{`type://EndpointSelector`}</a>{` and thus `}<a parentName="li" {...{
          "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
        }}>{`type://EndpointGroup`}</a>{` which is a sub-interface. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1910"
        }}>{`#1910`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2837"
        }}>{`#2837`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientRequestContext ctx = ...
EndpointGroup group = ...
// synchronous selection!
Endpoint endpoint = group.selectNow(ctx);
// asynchronous selection!
CompletableFuture<Endpoint> future =
        group.select(ctx, ctx.eventLoop(), 1000 /* timeout */ );
`}</code></pre>
        <ul parentName="li">
          <li parentName="ul">{`Thanks to this change, you can now avoid the `}<a parentName="li" {...{
              "href": "type://EmptyEndpointGroupException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EmptyEndpointGroupException.html"
            }}>{`type://EmptyEndpointGroupException`}</a>{` at least
for the case where the `}<a parentName="li" {...{
              "href": "typeplural://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
            }}>{`typeplural://Endpoint`}</a>{` disappear temporarily.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`You can now get the snapshot status of circuit breakers from monitoring system. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2841"
        }}>{`#2841`}</a></li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`You can now schedule a request timeout in nanoseconds. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2864"
        }}>{`#2864`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://ServiceRequestContext#setRequestTimeout(TimeoutMode,Duration):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html#setRequestTimeout(com.linecorp.armeria.common.util.TimeoutMode,java.time.Duration)"
            }}>{`type://ServiceRequestContext#setRequestTimeout(TimeoutMode,Duration)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://ClientRequestContext#setResponseTimeout(TimeoutMode,Duration):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html#setResponseTimeout(com.linecorp.armeria.common.util.TimeoutMode,java.time.Duration)"
            }}>{`type://ClientRequestContext#setResponseTimeout(TimeoutMode,Duration)`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`You can now see the error message by `}<inlineCode parentName="li">{`ResourceLeakDetector`}</inlineCode>{` if the `}<inlineCode parentName="li">{`DefaultClientFactory`}</inlineCode>{` is not closed
properly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2847"
        }}>{`#2847`}</a></li>
      <li parentName="ul">{`The initial connection latency is lowered by giving up hopeless HTTP/1.1 connection early when
HTTP/2 upgrade request fails. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2852"
        }}>{`#2852`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`Armeria server does not convert the `}<inlineCode parentName="li">{`Host`}</inlineCode>{` header to `}<inlineCode parentName="li">{`:authority`}</inlineCode>{` header anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2846"
        }}>{`#2846`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2850"
        }}>{`#2850`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://RequestHeaders#authority():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestHeaders.html#authority()"
            }}>{`type://RequestHeaders#authority()`}</a>{` looks into `}<inlineCode parentName="li">{`Host`}</inlineCode>{` header if `}<inlineCode parentName="li">{`:authority`}</inlineCode>{` header is missing.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://FileService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileService.html"
        }}>{`type://FileService`}</a>{` is not blocked by `}<inlineCode parentName="li">{`ClassLoader`}</inlineCode>{` lock anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1668"
        }}>{`#1668`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2845"
        }}>{`#2845`}</a></li>
      <li parentName="ul">{`You can retry when `}<a parentName="li" {...{
          "href": "type://DnsTimeoutException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/DnsTimeoutException.html"
        }}>{`type://DnsTimeoutException`}</a>{` is raised using `}<a parentName="li" {...{
          "href": "type://RetryRule#onUnprocessed():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRule.html#onUnprocessed()"
        }}>{`type://RetryRule#onUnprocessed()`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2836"
        }}>{`#2836`}</a></li>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`Http2Exception: failed to consume a HEADERS frame`}</inlineCode>{` error. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2832"
        }}>{`#2832`}</a></li>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`You tried to set the content preview twice`}</inlineCode>{` warning unless
you really did apply content previewing decorator twice. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2830"
        }}>{`#2830`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2832"
        }}>{`#2832`}</a></li>
      <li parentName="ul">{`A non-base `}<a parentName="li" {...{
          "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
        }}>{`type://WebClient`}</a>{` respects the user defined scheme correctly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2835"
        }}>{`#2835`}</a></li>
      <li parentName="ul">{`The specified port when building `}<a parentName="li" {...{
          "href": "type://EurekaUpdatingListener:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/eureka/EurekaUpdatingListener.html"
        }}>{`type://EurekaUpdatingListener`}</a>{` and `}<a parentName="li" {...{
          "href": "type://ZooKeeperUpdatingListener:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/zookeeper/ZooKeeperUpdatingListener.html"
        }}>{`type://ZooKeeperUpdatingListener`}</a>{`
is used in the registration information of an Armeria server. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2826"
        }}>{`#2826`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul">{`All Spring registration beans are deprecated. Use `}<a parentName="li" {...{
          "href": "type://ArmeriaServerConfigurator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/ArmeriaServerConfigurator.html"
        }}>{`type://ArmeriaServerConfigurator`}</a>{` and
`}<a parentName="li" {...{
          "href": "type://DocServiceConfigurator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/DocServiceConfigurator.html"
        }}>{`type://DocServiceConfigurator`}</a>{` instead. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2787"
        }}>{`#2787`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2838"
        }}>{`#2838`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Deprecated beans: `}<inlineCode parentName="li">{`HttpServiceRegistrationBean`}</inlineCode>{`, `}<inlineCode parentName="li">{`AnnotatedServiceRegistrationBean`}</inlineCode>{`,
`}<inlineCode parentName="li">{`GrpcServiceRegistrationBean`}</inlineCode>{` and `}<inlineCode parentName="li">{`ThriftServiceRegistrationBean`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`MeterIdPrefixFunctionFactory`}</inlineCode>{` has been deprecated in favor of `}<a parentName="li" {...{
          "href": "type://RequestLog#serviceName()"
        }}>{`type://RequestLog#serviceName()`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2780"
        }}>{`#2780`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2820"
        }}>{`#2820`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`executor()`}</inlineCode>{`, `}<inlineCode parentName="li">{`contextAwareExecutor()`}</inlineCode>{` and `}<inlineCode parentName="li">{`contextAwareEventLoop()`}</inlineCode>{` methods in
`}<a parentName="li" {...{
          "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
        }}>{`type://RequestContext`}</a>{` are deprecated. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2834"
        }}>{`#2834`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`on*(Predicate)`}</inlineCode>{` methods in the followings classes are deprecated. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2851"
        }}>{`#2851`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://RetryRule:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRule.html"
            }}>{`type://RetryRule`}</a>{` and `}<a parentName="li" {...{
              "href": "type://RetryRuleBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRuleBuilder.html"
            }}>{`type://RetryRuleBuilder`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://RetryRuleWithContent:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRuleWithContent.html"
            }}>{`type://RetryRuleWithContent`}</a>{` and `}<a parentName="li" {...{
              "href": "type://RetryRuleWithContentBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRuleWithContentBuilder.html"
            }}>{`type://RetryRuleWithContentBuilder`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://CircuitBreakerRule:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerRule.html"
            }}>{`type://CircuitBreakerRule`}</a>{` and `}<a parentName="li" {...{
              "href": "type://CircuitBreakerRuleBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerRuleBuilder.html"
            }}>{`type://CircuitBreakerRuleBuilder`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://CircuitBreakerRuleWithContent:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerRuleWithContent.html"
            }}>{`type://CircuitBreakerRuleWithContent`}</a>{` and `}<a parentName="li" {...{
              "href": "type://CircuitBreakerRuleWithContentBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerRuleWithContentBuilder.html"
            }}>{`type://CircuitBreakerRuleWithContentBuilder`}</a></li>
          <li parentName="ul">{`Use `}<inlineCode parentName="li">{`on*(BiPredicate)`}</inlineCode>{` methods instead.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`RequestLogBuilder.defer*Content*()`}</inlineCode>{` and `}<inlineCode parentName="li">{`isDefer*Content*Set()`}</inlineCode>{` have been deprecated in favor of
`}<inlineCode parentName="li">{`defer(RequestLogProperty)`}</inlineCode>{` and `}<inlineCode parentName="li">{`isDeferred(RequestLogProperty)`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2832"
        }}>{`#2832`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`MeterIdPrefixFunction.andThen(BiFunction<MeterRegistry, MeterIdPrefix, MeterIdPrefix>`}</inlineCode>{` is deprecated. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2839"
        }}>{`#2839`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`UnprocessedRequestException(Throwable)`}</inlineCode>{` constructor is deprecated.
Use `}<a parentName="li" {...{
          "href": "type://UnprocessedRequestException#of(Throwable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/UnprocessedRequestException.html#of(java.lang.Throwable)"
        }}>{`type://UnprocessedRequestException#of(Throwable)`}</a>{` instead. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2836"
        }}>{`#2836`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`The names of various artifacts are changed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2676"
        }}>{`#2676`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2677"
        }}>{`#2677`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2829"
        }}>{`#2829`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2843"
        }}>{`#2843`}</a>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`armeria-dropwizard`}</inlineCode>{` is now `}<inlineCode parentName="li">{`armeria-dropwizard2`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria-jetty`}</inlineCode>{` is now `}<inlineCode parentName="li">{`armeria-jetty9`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria-rxjava`}</inlineCode>{` is now `}<inlineCode parentName="li">{`armeria-rxjava3`}</inlineCode>{`.`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`rxjava`}</inlineCode>{` package is renamed to `}<inlineCode parentName="li">{`rxjava3`}</inlineCode>{`.`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria-spring-boot-actuator-autoconfigure`}</inlineCode>{` is now `}<inlineCode parentName="li">{`armeria-spring-boot2-actuator-autoconfigure`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria-spring-boot-actuator-starter`}</inlineCode>{` is now `}<inlineCode parentName="li">{`armeria-spring-boot2-actuator-starter`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria-spring-boot-autoconfigure`}</inlineCode>{` is now `}<inlineCode parentName="li">{`armeria-spring-boot2-autoconfigure`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria-spring-boot-starter`}</inlineCode>{` is now `}<inlineCode parentName="li">{`armeria-spring-boot2-starter`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria-spring-boot-webflux-autoconfigure`}</inlineCode>{` is now `}<inlineCode parentName="li">{`armeria-spring-boot2-webflux-autoconfigure`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria-spring-boot-webflux-starter`}</inlineCode>{` is now `}<inlineCode parentName="li">{`armeria-spring-boot2-webflux-starter`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria-testing-common`}</inlineCode>{` has been removed.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria-testing-junit4`}</inlineCode>{` is now `}<inlineCode parentName="li">{`armeria-junit4`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria-testing-junit`}</inlineCode>{` is now `}<inlineCode parentName="li">{`armeria-junit5`}</inlineCode>{`.`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`junit`}</inlineCode>{` package is renamed to `}<inlineCode parentName="li">{`junit5`}</inlineCode>{`.`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria-thrift`}</inlineCode>{` is now `}<inlineCode parentName="li">{`armeria-thrift0.13`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria-tomcat`}</inlineCode>{` is now `}<inlineCode parentName="li">{`armeria-tomcat9`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria-zookeeper`}</inlineCode>{` is now `}<inlineCode parentName="li">{`armeria-zookeeper3`}</inlineCode>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://RequestContext#eventLoop():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html#eventLoop()"
        }}>{`type://RequestContext#eventLoop()`}</a>{` now returns `}<a parentName="li" {...{
          "href": "type://ContextAwareEventLoop:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ContextAwareEventLoop.html"
        }}>{`type://ContextAwareEventLoop`}</a>{` which always set the
`}<a parentName="li" {...{
          "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
        }}>{`type://RequestContext`}</a>{` before executing any submitted tasks. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2629"
        }}>{`#2629`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2834"
        }}>{`#2834`}</a></li>
      <li parentName="ul">{`The default logging level for successful requests and response has been changed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2696"
        }}>{`#2696`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2855"
        }}>{`#2855`}</a>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`INFO`}</inlineCode>{` to `}<inlineCode parentName="li">{`DEBUG`}</inlineCode>{` for `}<inlineCode parentName="li">{`Logging{Client,Service}.newDecorator()`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`TRACE`}</inlineCode>{` to `}<inlineCode parentName="li">{`DEBUG`}</inlineCode>{` for `}<inlineCode parentName="li">{`Logging{Client,Service}Builder`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">{`The APIs of `}<a parentName="li" {...{
          "href": "type://HttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html"
        }}>{`type://HttpFile`}</a>{` and `}<a parentName="li" {...{
          "href": "type://HttpVfs:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpVfs.html"
        }}>{`type://HttpVfs`}</a>{` have been changed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1668"
        }}>{`#1668`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2845"
        }}>{`#2845`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2861"
        }}>{`#2861`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://AggregatedHttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/AggregatedHttpFile.html"
        }}>{`type://AggregatedHttpFile`}</a>{` does not extend `}<a parentName="li" {...{
          "href": "type://HttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html"
        }}>{`type://HttpFile`}</a>{` anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2861"
        }}>{`#2861`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://AbstractHttpFile#headers()"
        }}>{`type://AbstractHttpFile#headers()`}</a>{` is now `}<a parentName="li" {...{
          "href": "type://AbstractHttpFile#additionalHeaders():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/AbstractHttpFile.html#additionalHeaders()"
        }}>{`type://AbstractHttpFile#additionalHeaders()`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2861"
        }}>{`#2861`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://EndpointSelector:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointSelector.html"
        }}>{`type://EndpointSelector`}</a>{` API has been changed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2837"
        }}>{`#2837`}</a>{`  `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1910"
        }}>{`#1910`}</a>
        <ul parentName="li">
          <li parentName="ul">{`It is not a functional interface anymore.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`select()`}</inlineCode>{` has been renamed to `}<a parentName="li" {...{
              "href": "type://EndpointSelector#selectNow(ClientRequestContext):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointSelector.html#selectNow(com.linecorp.armeria.client.ClientRequestContext)"
            }}>{`type://EndpointSelector#selectNow(ClientRequestContext)`}</a>{`.`}</li>
          <li parentName="ul">{`An asynchronous `}<a parentName="li" {...{
              "href": "type://EndpointSelector#select(ClientRequestContext,ScheduledExecutorService,long):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointSelector.html#select(com.linecorp.armeria.client.ClientRequestContext,java.util.concurrent.ScheduledExecutorService,long)"
            }}>{`type://EndpointSelector#select(ClientRequestContext,ScheduledExecutorService,long)`}</a>{`
has been added.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://UserClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/UserClient.html"
        }}>{`type://UserClient`}</a>{` API has been changed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2837"
        }}>{`#2837`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Its constructor requires more parameters.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`execute()`}</inlineCode>{` requires less parameters.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`StreamMessage.drainAll()`}</inlineCode>{` and its variants have been removed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2782"
        }}>{`#2782`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2827"
        }}>{`#2827`}</a>
        <ul parentName="li">
          <li parentName="ul">{`They are only used for unit tests and you can use `}<inlineCode parentName="li">{`reactor.test.StepVerifier`}</inlineCode>{` instead.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`UnprocessedRequestException(String, Throwable)`}</inlineCode>{` constructor has been removed without replacement. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2836"
        }}>{`#2836`}</a></li>
      <li parentName="ul">{`Deprecated timeout APIs in `}<inlineCode parentName="li">{`{Service,Client}RequestContext`}</inlineCode>{` have been removed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2864"
        }}>{`#2864`}</a>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`extend{Request,Response}TimeoutMillis(long)`}</inlineCode>{` and `}<inlineCode parentName="li">{`extend{Request,Response}Timeout(Duration)`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`set{Request,Response}TimeoutAfterMillis(long)`}</inlineCode>{` and `}<inlineCode parentName="li">{`set{Request,Response}TimeoutAfter(Duration)`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`set{Request,Response}TimeoutAtMillis(long)`}</inlineCode>{` and `}<inlineCode parentName="li">{`set{Request,Response}TimeoutAt(Duration)`}</inlineCode></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`gRPC-Java 1.30.1 → 1.30.2`}</li>
      <li parentName="ul">{`Jackson 2.11.0 → 2.11.1`}</li>
      <li parentName="ul">{`Kafka 1.1.1 → 2.5.0`}</li>
      <li parentName="ul">{`Micrometer 1.5.1 → 1.5.2`}</li>
      <li parentName="ul">{`Reactor 3.3.6 → 3.3.7`}</li>
      <li parentName="ul">{`Shaded dependencies`}
        <ul parentName="li">
          <li parentName="ul">{`Caffeine 2.8.4 → 2.8.5`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Examples:`}
        <ul parentName="li">
          <li parentName="ul">{`Dagger 2.28 → 2.28.1`}</li>
          <li parentName="ul">{`monix_reactive 3.2.2`}</li>
          <li parentName="ul">{`ScalaPB 0.10.6`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Build:`}
        <ul parentName="li">
          <li parentName="ul">{`Checkstyle 8.33 → 8.34`}</li>
          <li parentName="ul">{`Eureka 1.9.23 → 1.9.25`}</li>
          <li parentName="ul">{`Finagle ServerSets 20.5.0 → 20.6.0`}</li>
          <li parentName="ul">{`Gradle 6.5 → 6.5.1`}</li>
          <li parentName="ul">{`gRPC-Kotlin 0.1.3 → 0.1.4`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'gary-lo', 'heowc', 'hexoul', 'ikhoon', 'jongmin92', 'minwoox', 'okue', 'tobias-', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      